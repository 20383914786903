import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';
// Form
import * as Yup from 'yup';
import { Formik } from 'formik';
// Consts & Libs
import AppAPI from '../../../lib/api';
import AppUtils from '../../../lib/util';
import { AppConfig, Constants, SelectList, Strings } from '../../../constants';
// Components
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../../../components/general/Alert';
import PolicyLinks from '../../../components/booking-engine/PolicyLinks';
import PlaceHolderImage from '../../../images/resource/place-holder-bg.svg';
import DateFormatted from '../../../components/booking-engine/DateFormatted';

import { Modal } from 'react-bootstrap';
import { Checkbox, Input, Select } from 'react-formik-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faContactCard,
  faFileContract,
  faMapMarker,
  faTicketAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import FAQList from '../../../components/booking-engine/FAQList';

/* ComponentConnect ==================================================================== */
const EventTicketBooking = (props) => {
  const { event, property, show, ticket, date } = props;
  const [resultMsg, setResultMsg] = useState();

  useEffect(() => setResultMsg({}), [show]);

  if (!show) return <></>;
  let ticket_quantity = 0;
  if (ticket.availability) {
    ticket_quantity = ticket.max_quantity;
    if (ticket.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && parseInt(ticket.availability.available_tickets) < parseInt(ticket_quantity)) {
      ticket_quantity = ticket.availability.available_tickets;
    }
  }

  const addTicket = (formData) => {
    if (formData) {
      setResultMsg({ status: 'One moment...' });
      AppAPI.bengineapi.post(`${property.property_slug}/event/${event.event_url}/ticket/create/`, {
        ticket_date: Moment(props.date).format('YYYY-MM-DD'),
        event_id: ticket.event_id,
        ticket_id: ticket.ticket_id,
        ticket_quantity: formData.quantity,
        ticket_guest: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: (formData.code + formData.phone),
          address_1: formData.address_1,
          address_2: formData.address_2,
          state: formData.state,
          country: formData.country ? formData.country : '',
          pin_code: formData.pin_code,
        },
        recaptcha: formData.recaptcha
      })
        .then(res => {
          if (res.hash) {
            setResultMsg({ success: 'Success' });
            setTimeout(() => {
              if (props.onSuccess) props.onSuccess(res);
            }, 500);
          } else {
            setResultMsg({ error: 'Booking Failed.' });
          }
        })
        .catch(err => setResultMsg({ error: AppAPI.handleError(err) }));
    }
  };

  const validation = {
    first_name: Yup.string().min(2, 'Too Short!').required('Required'),
    last_name: Yup.string().min(1, 'Too Short!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    code: Yup.string().required('Required'),
    quantity: Yup.number().required('Required'),
    recaptcha: Yup.string().required('Required'),
    phone: Yup.string().matches(
      AppConfig.phoneRegex, Strings.phoneNumberValidation
    ).min(8, 'to short').max(10, `${Strings.phoneNumberValidation} without appending 0`).required(
      Strings.phoneNumberValidation
    )
  }

  // Address Validation
  if (property.booking_engine_profile_address) {
    validation['address_1'] = Yup.string().required('Required');
    validation['address_2'] = Yup.string().required('Required');
    validation['country'] = Yup.string().required('Required');
    validation['state'] = Yup.string().required('Required');
    validation['pin_code'] = Yup.string().required('Required');
  }
  const formValidation = Yup.object().shape(validation);

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    code: property ? property.phone_number_code : '',
    country: property ? property.property_country_code : '',
    tands: false,
    recaptcha: '',
    quantity: 1,
    address_1: '',
    address_2: '',
    state: '',
    pin_code: '',
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="xl"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={'d-flex flex-column'}>
            <p className={'mb-0 '}><strong>{event.name} </strong></p>
            <p className={'mb-0 '}>
              <small>{ticket.name} | Booking date : {AppUtils.formatDateTime(date, 'datef')} </small>
            </p>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(data) => addTicket(data)}>
          {({ values, errors, touched, setFieldValue, handleSubmit }) => (
            <React.Fragment>
              <Modal.Body>
                <div className={'row'}>
                  <div className={'col-md-4 col-sm-12 mb-3 d-none d-lg-block'}>
                    <div className={'event-cover mb-3'}>
                      {(event.event_cover_image && event.event_cover_image.cover_sm)
                        ? <img className="card-img-top" alt={event.name} src={event.event_cover_image.cover_sm}/>
                        : <img alt={'Empty Cover'} className="card-img-top bg-dark" src={PlaceHolderImage}/>
                      }
                    </div>
                    <h5>{event.name}</h5>
                    <p className={'small text-muted'}>
                      <FontAwesomeIcon icon={faMapMarker} className={'me-2'}/>
                      {event.full_address ? event.full_address : property.full_address}
                    </p>
                    <p className={'small'}><strong>Booking Date</strong></p>
                    <div className={'row'}>
                      <div className={'col d-inline-flex'}>
                        <DateFormatted date={date}/>
                      </div>
                    </div>
                    <div className={'border rounded p-2 mt-3'}>
                      <p className={'mb-1 green-cl'}><strong>Go Green</strong></p>
                      <p className={'small text-muted'}>{Strings.digitalAdopterPrint}</p>
                    </div>
                  </div>
                  <div className={'col-lg-8 col-sm-12 border-start'}>
                    <h6 className={'mb-0'}>
                      <FontAwesomeIcon icon={faTicketAlt} size={'sm'} className={'grey-cl me-1'}/> Ticket
                    </h6>
                    <p className={'small text-muted'}>Select the required quantity of ticket(s).</p>
                    <div className="list-group">
                      <div className={'list-group-item'}>
                        <div className={'row'}>
                          <div className={'col-8 col-md-8 col-lg-9 mb-3'}>
                            <p className={'small mb-1 text-muted'}>Ticket</p>
                            <div className={'d-flex flex-row'}>
                              <DateFormatted date={date}/>
                              <div className={'ms-3 align-self-center'}>
                                <p className={'mb-0'}>
                                  <strong>{ticket.name}</strong>
                                </p>
                                <p className={'small text-muted'}>{ticket.currency} {ticket.base_price_effective} /
                                  ticket</p>
                              </div>
                            </div>
                          </div>
                          <div className={'col-4 col-md-4 col-lg-3'}>
                            <p className={'small mb-1 text-muted'}>Quantity</p>
                            <div>
                              <Select
                                required
                                className={'form-select small'}
                                name="quantity"
                                placeholder="Quantity"
                                options={AppUtils.numericSelector(1, ticket_quantity)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={'list-group-item'}>
                        <div className={'row'}>
                          <div className={'col-5'}>
                            <p className={'mb-0'}>
                              <strong>Total</strong>
                            </p>
                          </div>
                          <div className={'col-7 text-end'}>
                            <p className={'mb-0'}>
                              <strong>{ticket.currency} {values.quantity * parseFloat(ticket.base_price_effective)}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className={'mb-0 mt-2 text-muted small'}>
                      Inclusive of taxes, breakdown available in next page.
                    </p>
                    <hr/>
                    {ticket.description &&
                    <div>
                      <p className={'text-muted font-weight-light small'}>
                        {ticket.description}
                      </p>
                    </div>
                    }
                    {ticket.alert_description &&
                    <div>
                      <div className="alert alert-warning" role="alert">
                        <p className={'mb-0 small text-uppercase'}><strong>Note </strong></p>
                        <p className={'mb-0 small'}>{ticket.alert_description}</p>
                      </div>
                    </div>
                    }

                    <div className={'row'}>
                      <div className={'col-sm-12'}>
                        <h6 className={'mb-0'}>
                          <FontAwesomeIcon icon={faUser} size={'sm'} className={'grey-cl me-1'}/> Name
                        </h6>
                        <p className={'small text-muted'}>Name of the primary contact for the booking.</p>
                        <div className="row mb-3 mb-2">
                          <div className="col-6 form-group">
                            <Input
                              required
                              name="first_name"
                              label={'First Name'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-6 form-group">
                            <Input
                              required
                              name="last_name"
                              label={'Last Name'}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <h6 className={'mb-0'}>
                          <FontAwesomeIcon icon={faFileContract} size={'sm'} className={'grey-cl me-1'}/> Contact
                        </h6>
                        <p className={'small text-muted'}>Where would you like to receive the ticket and be contacted if
                          required ?</p>
                        <div className="row mb-3">
                          <div className="col-lg-6 col-md-6 form-group">
                            <Input
                              required
                              name="email"
                              type={'email'}
                              label={'E-mail'}
                              className={'form-control'}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 ">
                            <div className={'row'}>
                              <div className={'col-4 pr-0 form-group'}>
                                <Select
                                  className={'form-control rounded-right-0'}
                                  name="code"
                                  label={'Country'}
                                  placeholder="Select an Option"
                                  options={SelectList.phoneCountryCode()}
                                />
                              </div>
                              <div className={'col-8 pl-0 form-group'}>
                                <Input
                                  required
                                  type={'tel'}
                                  name="phone"
                                  label={'Phone'}
                                  className={'form-control rounded-left-0'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {property.booking_engine_profile_address && (
                          <>
                            <hr/>
                            <h6 className={'mb-0'}>
                              <FontAwesomeIcon icon={faContactCard} size={'sm'} className={'grey-cl me-1'}/> Address
                            </h6>
                            <p className={'small text-muted'}>Billing / Booking address</p>
                            <div className="mb-3">
                              <div className={'row'}>
                                <div className="col-6 form-group">
                                  <Input
                                    name='address_1'
                                    label={'Address Line 1'}
                                    className={'form-control'}
                                    placeholder={'House No / Name'}
                                  />
                                </div>
                                <div className="col-6 form-group">
                                  <Input
                                    name='address_2'
                                    label={'Address Line 2'}
                                    className={'form-control'}
                                    placeholder={'District'}
                                  />
                                </div>
                              </div>
                              <div className={'row'}>
                                <div className="col-4 form-group">
                                  {values.country && !_.isEmpty(SelectList.stateList(values.country))
                                    ? (
                                      <Select
                                        className={'form-control rounded-right-0'}
                                        name='state'
                                        label={'State'}
                                        placeholder='Select an Option'
                                        options={SelectList.stateList(values.country)}
                                      />
                                    ) : (
                                      <Input
                                        name='state'
                                        label={'State'}
                                        className={'form-control'}
                                        disabled={!values.country}
                                      />
                                    )}
                                </div>
                                <div className="col-4 form-group">
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name='country'
                                    label={'Country'}
                                    placeholder='Select an Option'
                                    options={SelectList.countryList()}
                                  />
                                </div>
                                <div className="col-4 form-group">
                                  <Input
                                    name='pin_code'
                                    label={'Pin Code'}
                                    className={'form-control'}
                                    type={'number'}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr/>
                          </>
                        )}

                        <div className="row mb-3">
                          <div className="col-12 form-group">
                            <Checkbox
                              required
                              className={'me-2'}
                              name="tands"
                              text="Terms And Conditions"
                            />
                            <p className={'small text-muted'}>
                              I agree to {property.name} Terms of Service & Privacy Policy ( listed below ) and
                              Renzo's <a rel="noopener noreferrer" target={'_blank'}
                                href={'https://renzo.in/policies/'}>Terms of service & Privacy Policy</a>.
                            </p>
                            {!_.isEmpty(property.global_content) &&
                            <PolicyLinks inLine={true} Links={property.global_content.policy_links}/>
                            }
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 form-group">
                            <div className={'form-element input'}>
                              <span className={'label'}>Recaptcha Validation</span>
                              <ReCAPTCHA
                                sitekey={AppConfig.reCaptchaKey}
                                onChange={(data) => setFieldValue('recaptcha', data)}
                                onExpired={() => setFieldValue('recaptcha', '')}
                              />
                              {errors.recaptcha
                              && touched.recaptcha && (
                                <span className={'error'}>{errors.recaptcha}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_event_booking_rules)) &&
                        <div>
                          <FAQList
                            title={'Booking Rules'}
                            FAQList={property.booking_engine_content.bengine_event_booking_rules}
                          />
                        </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className={'row w-100'}>
                  <div className={'col-12 col-md-12 col-lg-8'}>
                    <Alert statusMsg={resultMsg}/>
                  </div>
                  <div className={'col-12 col-md-12 col-lg-4'}>
                    <div className={'d-grid'}>
                      <button onClick={handleSubmit} className={'btn btn-success btn-lg'} type={'submit'}>
                        Place Order <FontAwesomeIcon className={'white-cl me-2'} icon={faArrowRight}/>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

EventTicketBooking.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  date: PropTypes.object,
  event: PropTypes.object,
  ticket: PropTypes.object,
  onSuccess: PropTypes.func,
  property: PropTypes.object
};

/* Export ==================================================================== */
export default EventTicketBooking;
